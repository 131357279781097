body {
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  body::after {
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: inherit;
    backdrop-filter: blur(10px);
    mask-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    pointer-events: none;
    z-index: 999;
  }
  
  .pageContainer {
    min-height: 100vh;
    padding: 2rem 2rem 5rem 2rem;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    flex-direction: column;
  }
  
  .header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
    position: relative;
    z-index: 10;
  }
  
  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-grow: 1;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .fontSection {
    display: flex;
    gap: 48px;
    flex-wrap: wrap;
  }
  
  .fontInfo {
    max-width: 200px;
    flex: 0 0 200px;
    box-sizing: border-box;
    text-align: right;
  }
  
  .fontTitle {
    margin-top: 16px;
  }
  
  .fontSubtitle {
    margin-top: 8px;
  }
  
  .fontTitle, .fontSubtitle {
    font-size: 1.2rem;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    margin-bottom: 0.5rem;
  }
  
  .fontTitle a, .fontSubtitle a {
    color: inherit;
    text-decoration: none;
  }
  
  .fontTitle a:hover, .fontSubtitle a:hover {
    text-decoration: underline;
  }
  
  .fontDescription {
    font-size: 0.9rem;
    opacity: 0.7;
    margin-bottom: 1rem;
    margin-top: 0;
  }
  
  .fontControl {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  }
  
  .fontControl input[type="range"] {
    margin: 0 0.5rem;
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: var(--slider-color);
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
  }
  
  .fontControl input[type="range"]:hover {
    opacity: 1;
  }
  
  .fontControl input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: var(--slider-color);
    cursor: pointer;
    border-radius: 50%;
  }
  
  .fontControl input[type="range"]::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: var(--slider-color);
    cursor: pointer;
    border-radius: 50%;
  }
  
  .title {
    font-size: 2.5rem;
    line-height: 1.2;
    margin: 0;
    transition: opacity 0.3s ease-in-out;
    flex-grow: 1;
    margin-bottom: 40px;
    flex-basis: 0;
    min-width: 0;
  }
  
  .body {
    line-height: 1.6;
    transition: opacity 0.3s ease-in-out;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    position: relative;
  }
  
  .body p {
    margin-top: 0;
  }
  
  .changing {
    opacity: 0;
  }
  
  .modeToggle {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    color: inherit;
    transition: transform 0.3s ease-in-out;
  }
  
  .modeToggle:hover {
    transform: scale(1.1);
  }
  
  .tooltipContainer {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    display: none;
    visibility: hidden;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltipContainer:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  .footer {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    position: sticky;
    bottom: 24px;
    /* left: 58%;
    transform: translateX(-50%); */
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.9);
    /* border: 1px solid rgba(0, 0, 0, .5); */
    border: 1px solid; /* Ensure border is defined */
    border-radius: 24px;
    z-index: 1000;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
  }
  
  .spacebarHint {
    font-size: 0.9rem;
    opacity: 0.7;
    align-items: center;
    gap: 5px;
  }

  .footerButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .changeFontButton {
    flex: 1;
    margin: 0 5px;
    padding: 12px 8px;
    font-size: 0.9rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.3s;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    border-radius: 12px;
  }
  
  .changeFontButton:hover {
    opacity: 0.8;
  }
  
  /* .changeFontButton {
    border: none;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 4px;
    cursor: pointer;
    transition: opacity 0.3s;
    font-family: "Source Sans Pro", sans-serif;
    border-radius: 12px;
  } */
  
  @media (max-width: 768px) {
    .pageContainer {
      padding: 1rem;
    }
  
    .content {
      flex: none;
    }
  
    .fontInfo {
      flex: none;
      max-width: none;
      width: 100%;
      text-align: left;
    }
  
    .fontSection {
      display: block;
      flex: none;
    }
  
    .fontControl {
      display: none;
    }
  
    .footer {
      padding: 10px;
      flex-direction: row;
      justify-content: space-around;
    }

    .footerButtons {
        flex-direction: row;
    }
  
    .changeFontButton {
      flex: 1;
      margin: 0 5px;
      padding: 12px 8px;
      font-size: 0.9rem;
    }
  }